<template>
    <div>
        <div>
            <div class="home mt-5">

                <fullscreen-loading v-if="loading"></fullscreen-loading>

                <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                    <div class="row">
                        <div class="col-12">
                            <div class="fusion-title">
                                <h3 class="h3-green" style="font-size:50px;">Gebruikers</h3>
                            </div>
                            <p>
                                Hier vind je alle gebruikers terug die in het systeem staan geregistreerd.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-3">
                        <settings-menu></settings-menu>
                    </div>

                    <div class="col-9">
                        <div class="row">
                            <h2 class="title-heading-left mb-3" style="">Gebruikers</h2>

                            <div class="d-flex mb-4">
                                <input placeholder='Zoek op gebruikersnaam' name="username" @keydown.enter="getUsers(1)"
                                       v-model="search" type="text" class="form-control input-sm">
                                <button @click="getUsers(1)" class="btn btn-primary">
                                    <font-awesome-icon icon="search"></font-awesome-icon>
                                </button>
                            </div>

                            <div v-if="!users.data.length && !loading" class="col-12">
                                <div class="alert alert-info">
                                    Er zijn nog geen gebruikers
                                </div>
                            </div>

                            <div class="col-12 mb-3" v-for="user in users.data" :key="user.id">
                                <div class="card signcard">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="float-start">
                                                    <h2 class="h3-card-header">{{ user.name }} - <small>{{ user.email }}</small></h2>
                                                </div>
                                                <button class="float-end btn btn-sm btn-primary" :disabled="user.admin === 1" @click="loginAsUser(user)">
                                                    <font-awesome-icon icon="user"></font-awesome-icon>
                                                </button>

                                                <div class="row">
                                                    <div class="col-4">
                                                        <small>Aangemaakt op: {{ user.created_at | moment('DD-MM-YYYY HH:mm') }}</small> <br>
                                                    </div>
                                                    <div class="col-4">
                                                        <small>Bedrijf: {{ user.company.name }}</small>
                                                    </div>
                                                    <div class="col-4">
                                                        <small>Aantal ondertekeningen: {{ user.company.sign_requests.length }}</small>
                                                    </div>
                                                    <div class="col-4">
                                                        <small>Aantal credits: {{ user.company.credits }}</small>
                                                    </div>
                                                    <div class="col-4">
                                                        <small v-if="user.company.current_payment_plan">Huidig plan: {{ user.company.current_payment_plan.name }}</small>
                                                    </div>
                                                    <div class="col-4">
                                                        <small>Volgende betaling: {{ user.company.next_payment_date || 'nvt' }}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <Pagination :pagination="users" @change="getUsers()"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import SettingsMenu from "@/components/SettingsMenu";
    import FullscreenLoading from "@/components/FullscreenLoading";
    import Pagination from '../../components/Pagination';

    export default {

        components: {
            FullscreenLoading,
            SettingsMenu,
            Pagination
        },

        data() {
            return {
                loading: false,
                order: 'id,desc',
                search: '',
                filter: {
                    ordering: 'id,desc'
                },
                users: {
                    data: []
                }
            }
        },

        mounted() {
            this.getUsers();
            this.filter = {
                ...this.filter,
                ...this.$route.query
            };
        },

        methods: {
            async getUsers(page = undefined) {
                this.loading = true;

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/all-users', {
                        params: {
                            page: page || this.users.current_page,
                            order: this.order,
                            limit: 10,
                            search: this.search
                        }
                    });

                    this.users = response.data.users;

                } catch (exception) {
                    this.errormessage = exception
                }

                this.loading = false;
            },


            async loginAsUser(user) {

                try {

                    const response = await this.$http.post(this.$root.api_url + '/api/login-as-user', user);


                    await this.$store.dispatch('login', {
                        user: response.data.user,
                        token: response.data.token
                    });

                    await this.$router.push('/');


                } catch (exception) {
                    this.errormessage = exception
                }

            }
        }
    }

</script>

<style scoped>

</style>
